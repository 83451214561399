import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
// import { ThemeProvider } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';

import { MyMenu } from './MyMenu';
import { MyAppBar } from './MyAppBar';
import { MySidebar } from './MySidebar';
import { MyNotification } from './MyNotification';
import { setSidebarVisibility } from 'react-admin';
import { useHistory } from 'react-router-dom';
/*
import {
  AppLocationContext,
  Breadcrumb,
  ResourceBreadcrumbItems,
} from '@react-admin/ra-navigation';
*/

import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import {
  BookOutlined,
  HomeOutlined,
  MenuBookOutlined,
  RepeatOutlined,
  ShoppingBasketOutlined,
  VerifiedUserOutlined,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    minHeight: '100vh',
    backgroundColor: '#fff', //theme.palette.background.default,
    position: 'relative',
    overflow: 'hidden',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    //overflowX: 'auto',
    xxx_maxWidth: 800,
    width: '100%',
    margin: '0 auto',
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
    //flexFlow: 'row-reverse',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    padding: theme.spacing(1),
    marginTop: 56, //'4em',
    marginBottom: 100,
    //paddingLeft: 5,
    background: '#fff',
    zIndex: 1,
  },
  bottomNav: {},
}));

// Include this component in your views. See below
export const MyBreadcrumb = (/*props*/) => (
  <></>
  // <Breadcrumb {...props}>
  // <ResourceBreadcrumbItems />
  // </Breadcrumb>
);

export const MyLayout = ({
  children,
  dashboard,
  logout,
  title,
  /*, ...props */
}) => {
  const location = useLocation();
  // Check if the pathname ends with '/create'
  const isCreatePage = location.pathname.endsWith('/create');
  // Check if the pathname matches the pattern of an edit page (ends with a number)
  const isEditPage = /\/\d+$/.test(location.pathname);
  // Check if the pathname matches the pattern of an edit page (ends with a number)
  const isFormPage = !!(isCreatePage || isEditPage);

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const [bnValue, bnSet] = useState('home');
  const bnChange = (event, newValue) => {
    const target = event.target;
    const dataset = Object.assign({}, target.dataset || {});
    const value = dataset.value;
    console.log('bnChange', { newValue, event, target, dataset, value });
    bnSet(bnValue);
    //Catalogue;
    if (newValue == 0) {
      history.push('/or.product');
    }
    //Subscriptions;
    if (newValue == 1) {
      history.push('/or.account.subscription');
    }
    //Home;
    if (newValue == 2) {
      history.push('/');
    }
    //Orders;
    if (newValue == 3) {
      history.push('/or.invoice');
    }
    //Menu;
    if (newValue == 4) {
      dispatch(setSidebarVisibility(!open));
    }
  };

  //useEffect(() => {
  //  dispatch(setSidebarVisibility(true));
  //}, [setSidebarVisibility]);

  return (
    // <AppLocationContext hasDashboard={!!props.dashboard}>
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <MyAppBar title={title} open={open} logout={logout} />
        {!isFormPage ? (
          <BottomNavigation
            className={classes.bottomNav}
            value={bnValue}
            onChange={bnChange}
            showLabels
          >
            <BottomNavigationAction
              data-value="catalogue"
              label="Catalogue"
              icon={<BookOutlined />}
            />
            {!isSmall ? (
              <BottomNavigationAction
                data-value="subscriptions"
                label="Subscriptions"
                icon={<RepeatOutlined />}
              />
            ) : null}
            <BottomNavigationAction
              data-value="home"
              label="Home"
              icon={<HomeOutlined />}
            />
            {!isSmall ? (
              <BottomNavigationAction
                data-value="orders"
                label="Orders"
                icon={<ShoppingBasketOutlined />}
              />
            ) : null}
            <BottomNavigationAction
              data-value="menu"
              label="Menu"
              icon={<MenuBookOutlined />}
            />
          </BottomNavigation>
        ) : null}
        <main className={classes.contentWithSidebar}>
          <MySidebar>
            <MyMenu logout={logout} hasDashboard={!!dashboard} />
          </MySidebar>
          <div className={classes.content}>{children}</div>
        </main>
        <MyNotification />
      </div>
    </div>
    // </AppLocationContext>
  );
};
/*
<AppLocationContext hasDashboard={!!props.dashboard}>
  <Layout
    {...props}
    appBar={() => <MyAppBar title={title} open={open} logout={logout} />}
    sidebar={() => (
      <MySidebar>
        <MyMenu logout={logout} hasDashboard={!!dashboard} />
      </MySidebar>
    )}
    menu={() => <MyMenu logout={logout} hasDashboard={!!dashboard} />}
    notification={MyNotification}
  >
    <div className={classes.content}>{children}</div>
  </Layout>
</AppLocationContext>
*/

MyLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  // logout: ComponentPropType,
  title: PropTypes.string.isRequired,
};

export default MyLayout;
