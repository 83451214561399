export default {
  //'ra.message.delete_title': 'Delete Record',
  //'ra.message.delete_content': (record, resource) =>
  //  `Are you sure you want to delete this ${
  //    resource.options && resource.options.label
  //      ? resource.options.label
  //      : resource.name
  //  } #${record.id}?`,

  resources: {
    'or.invoice': { name: 'Invoice |||| Invoices' },
    'or.invoice.component': { name: 'Component |||| Components' },
    'or.invoice.signature': { name: 'Signature |||| Signatures' },
    'or.invoice.image': { name: 'Image |||| Images' },
    'or.invoice.audio': { name: 'Audio |||| Audios' },
    'or.invoice.video': { name: 'Video |||| Videos' },
    'or.invoice.coupon': { name: 'Coupon |||| Coupons' },
    'or.invoice.discount': { name: 'Discount |||| Discounts' },
    'or.invoice.refund': { name: 'Refund |||| Refunds' },
    'or.invoice.transaction': { name: 'Transaction |||| Transactions' },
    'or.invoice.billination': { name: 'Billination |||| Billinations' },
    'or.invoice.shipping': { name: 'Shipping |||| Shippings' },
    'or.invoice.destination': { name: 'Destination |||| Destinations' },
    'or.invoice.occasion': { name: 'Occasion |||| Occasions' },
    'or.invoice.job': { name: 'Job |||| Jobs' },
    'or.invoice.item': { name: 'Item |||| Items' },
    'or.invoice.item.delivery': { name: 'Delivery |||| Deliverys' },
    'or.invoice.item.commitment': { name: 'Commitment |||| Commitments' },
    'or.quote': { name: 'Quote |||| Quotes' },
    'or.quote.component': { name: 'Component |||| Components' },
    'or.quote.signature': { name: 'Signature |||| Signatures' },
    'or.quote.image': { name: 'Image |||| Images' },
    'or.quote.audio': { name: 'Audio |||| Audios' },
    'or.quote.video': { name: 'Video |||| Videos' },
    'or.quote.job': { name: 'Job |||| Jobs' },
    'or.quote.item': { name: 'Item |||| Items' },
    'or.quote.coupon': { name: 'Coupon |||| Coupons' },
    'or.quote.discount': { name: 'Discount |||| Discounts' },
    'or.quote.billination': { name: 'Billination |||| Billinations' },
    'or.quote.destination': { name: 'Destination |||| Destinations' },
    'or.profi': { name: 'Profi |||| Profis' },
    'or.profile.addre': { name: 'Addre |||| Addres' },
    'or.page': { name: 'Page |||| Pages' },
    'or.page.album': { name: 'Album |||| Albums' },
    'or.page.attachment': { name: 'Attachment |||| Attachments' },
    'or.page.audio': { name: 'Audio |||| Audios' },
    'or.page.brand': { name: 'Brand |||| Brands' },
    'or.page.brochure': { name: 'Brochure |||| Brochures' },
    'or.page.collection': { name: 'Collection |||| Collections' },
    'or.page.colour': { name: 'Colour |||| Colours' },
    'or.page.comment': { name: 'Comment |||| Comments' },
    'or.page.download': { name: 'Download |||| Downloads' },
    'or.page.faq': { name: 'Faq |||| Faqs' },
    'or.page.file': { name: 'File |||| Files' },
    'or.page.image': { name: 'Image |||| Images' },
    'or.page.model': { name: 'Model |||| Models' },
    'or.page.price': { name: 'Price |||| Prices' },
    'or.page.review': { name: 'Review |||| Reviews' },
    'or.page.scrap': { name: 'Scrap |||| Scraps' },
    'or.page.size': { name: 'Size |||| Sizes' },
    'or.page.video': { name: 'Video |||| Videos' },
    'or.product': { name: 'Product |||| Products' },
    'or.product.album': { name: 'Album |||| Albums' },
    'or.product.attachment': { name: 'Attachment |||| Attachments' },
    'or.product.audio': { name: 'Audio |||| Audios' },
    'or.product.brand': { name: 'Brand |||| Brands' },
    'or.product.brochure': { name: 'Brochure |||| Brochures' },
    'or.product.collection': { name: 'Collection |||| Collections' },
    'or.product.colour': { name: 'Colour |||| Colours' },
    'or.product.comment': { name: 'Comment |||| Comments' },
    'or.product.download': { name: 'Download |||| Downloads' },
    'or.product.faq': { name: 'Faq |||| Faqs' },
    'or.product.file': { name: 'File |||| Files' },
    'or.product.image': { name: 'Image |||| Images' },
    'or.product.model': { name: 'Model |||| Models' },
    'or.product.price': { name: 'Price |||| Prices' },
    'or.product.review': { name: 'Review |||| Reviews' },
    'or.product.scrap': { name: 'Scrap |||| Scraps' },
    'or.product.size': { name: 'Size |||| Sizes' },
    'or.product.video': { name: 'Video |||| Videos' },
    'or.service': { name: 'Service |||| Services' },
    'or.service.option': { name: 'Option |||| Options' },
    'or.account.subscription': { name: 'Subscription |||| Subscriptions' },
    'or.account.payment_method': {
      name: 'Payment Method |||| Payment Methods',
    },
    'or.account.reminder': {
      name: 'Reminder |||| Reminders',
      fields: {
        repeat: 'Frequency',
        notice: 'Notice period',
        date: 'Special Date',
        occasion: 'Special Occasion',
      },
    },
  },

  ra: {
    auth: {
      password: 'Account Password',
      username: 'Account Email',

      login_intro: 'Welcome',
      login_button: 'Login',
      login_invalid: 'Invalid username or password',
      login_forgot_link: 'Forgot Password?',
      login_title: 'Please Login',
      login_remember: 'Remember login?',
      login_register_link: 'Register now',
      login_success: 'Login success',
      login_error: 'Login error',

      register_button: 'Register Now',
      register_invalid: 'Invalid information',
      register_title: 'Register Now',
      register_login_link: 'Already have an account? Login here',
      register_success: 'Registration confirmed!',
      register_error: 'Registration error',

      forgot_title: 'Forgot Your Password?',
      forgot_send_link: 'Send Link',
      forgot_back_to_login: 'Back to Login',
      forgot_no_account_register: 'No account? Sign Up',

      first_title: 'Welcome!',
      first_intro: `Just type the email you used in your booking into the box below and click "Send Link"`,
      first_send_link: 'Send Link',
      first_back_to_login: 'Back to Login',
      first_no_account_register: 'No account? Sign Up',

      reset_button: 'Reset password',
      reset_invalid: 'Invalid information',
      reset_unknown_error: 'Unknown error, please contact us',
      reset_account_not_found: 'Did not find account with that email',
      reset_email_sent: 'Password reset link sent to your email',
      reset_title: 'Reset Password',
      reset_success: 'Password reset!',
      reset_error: 'Could not reset password',
    },
    page: {
      contact: {
        title: 'Contact Us',
        button: 'Send Message',
        success: 'Message sent!',
        invalid: 'Invalid information',
        error: 'Error',
      },

      accessorise: {
        title: 'Add Accessories',
        button: 'Add to order',
        success: 'Your order has been updated',
        invalid: 'Invalid information',
        error: 'Error',
      },

      invoice: {
        list: {
          title: 'Your Orders',
        },
        payment: {
          title: 'Pay Now',
          button: 'Pay Now',
          success: 'Payment Successful',
          invalid: 'Payment Failed',
          error: 'Error',
        },
      },

      reschedule: {
        title: 'Reschedule',
        button: 'Reschedule',
        success: 'Schedule changed!',
        invalid: 'Invalid information',
        error: 'Error',
        intro: 'Please complete the fields below to change this booking',

        timeslots: {
          success: 'Got timeslots for this date',
          error: 'Error getting timeslots for this date',
        },

        errors: {
          QUOTE_REQUIRED: 'quote_required',
          SERVICE_REQUIRED: 'service_required',
          OPTION_REQUIRED: 'option_required',
          DATE_REQUIRED: 'You must select a new date',
          TIME_REQUIRED: 'You must select a new time slot',
          SLOT_NO_LONGER_AVAILABLE: 'Slot no longer available',
          SLOT_FULL: 'The slow is full now',
          SLOT_UNAVAILABLE: 'Slot unavailable',
          SERVICE_OPTION_UNAVAILABLE: 'This service is no longer available',
        },
      },

      feedback: {
        title: 'Feedback',
        button: 'Send Feedback',
        success: 'Feedback sent!',
        invalid: 'Invalid information',
        error: 'Error',

        regarding: {
          title: 'Rate This Job',
          button: 'Send Feedback',
          success: 'Feedback sent!',
          invalid: 'Invalid information',
          error: 'Error',
        },
      },

      help: {
        button: 'Get Help Now',
        invalid: 'Invalid information',
        title: 'Request Help',
        success: 'Message sent!',
        error: 'Error',
      },

      job: {
        list: {
          title: 'Job Bookings',
        },
        document: {
          download: {
            success: 'Document has downloaded',
          },
        },
      },
    },
    error: {
      password_mismatch: 'Passwords did not match',
      name_required: 'Name required',
      email_required: 'Email required',
      mobile_required: 'Mobile required',
      program_required: 'Program required',
    },
    menu: {
      title: 'Menu',
    },
    feats: {
      D: {
        D_FEAT_1: 'Personal driver',
        D_FEAT_2: 'SMS and Driver Tracking',
        D_FEAT_3: 'On the date of your choice',
        D_FEAT_4: 'Select time slot',
        D_FEAT_5: "We'll bring it in",
        D_FEAT_6: "We'll install it onto the stand",
        D_FEAT_7: "We'll put the lights on",
        D_FEAT_8: "We'll fully decorate it to your requirements",
        D_FEAT_9: "We can't provide a specific arrival window in advance",
        D_FEAT_9x: 'you can get an estimate time on the day',
        D_FEAT_CP: 'Packages only, not for Christmas trees',
        D_FEAT_CT: '3rd-Party courier tree drop off only',
        D_FEAT_WE: 'Weekend (Saturday) Delivery',
        D_FEAT_WD: 'Weekday Delivery (Mon-Fri)',
        D_FEAT_CL: 'Delivery by 09:30',
        D_FEAT_CM: 'Morning Delivery by 10:30',
        D_FEAT_CN: 'Morning Delivery by 12:30',
        D_FEAT_CS: 'Saturday Delivery by 14:00',
        D_FEAT_T09: 'For trees up to 9ft',
        D_FEAT_T12: 'For bigger trees up to 12ft',
      },
      C: {
        C_FEAT_1: 'On the date of your choice',
        C_FEAT_2: 'Select time slot',
        C_FEAT_3: "We'll recycle the tree",
        C_FEAT_4: 'Anti-needle sheet',
        C_FEAT_5: "We'll undress the tree",
        C_FEAT_6: "We'll pack the decorations",
        C_FEAT_7: "We'll clean up",
        C_FEAT_8: "We can't provide a specific arrival window in advance",
        C_FEAT_8x: 'you can get an estimate time on the day',
        C_FEAT_9: 'The tree must be undressed prior to our arrival',
        C_FEAT_B: 'The tree must be outside!',
      },
    },
  },
};
