import ReactGA from 'react-ga';
export const trackers = [
  {
    id: 'UA-4414122-9',
    name: 'default',
  },
  {
    id: 'UA-54530413-2',
    name: 'fwxcms',
  },
];
const trackerOptions = trackers.map((t) => ({
  trackingId: t.id, //'UA-816500-13',
  gaOptions: {
    name: t.name, //'default',
    //userId: 123,
  },
}));
const trackerCodes = trackers.map((t) => t.id);
const trackerNames = trackers.map((t) => t.name);

// initialize GA
// ReactGA.initialize('UA-816500-13');
// initialize GA for multiple properties
ReactGA.initialize(
  trackerOptions
  //{ debug: true, alwaysSendToDefaultTracker: false },
  //    gaTrackingID	String. Required. GA Tracking ID like UA-000000-01.
  //    options.debug	Boolean. Optional. If set to true, will output additional feedback to the console.
  //    options.titleCase	Boolean. Optional. Defaults to true. If set to false, strings will not be converted to title case before sending to GA.
  //    options.gaOptions	Object. Optional. GA configurable create only fields.
  //    options.gaAddress	String. Optional. If you are self-hosting your analytics.js, you can specify the URL for it here.
  //    options.alwaysSendToDefaultTracker	Boolean. Optional. Defaults to true. If set to false and using multiple trackers, the event will not be send to the default tracker.
  //    options.testMode	Boolean. Optional. Defaults to false. Enables test mode. See here for more information.
  //    options.standardImplementation	Boolean. Optional. Defaults to false. Enables loading GA as google expects it. See here for more information.
  //    options.useExistingGa	Boolean. Optional. Skips call to window.ga(), assuming you have manually run it.
  //    options.redactEmail	Boolean. Optional. Defaults to true. Enables redacting a email as the string that in "Event Category" and "Event Action".
);

export const MultipleTrackerGA = (window.ReactGA = {
  trackers,
  set: function () {
    console.log('ReactGA.set', [...arguments, trackerNames], {
      trackers,
      trackerCodes,
      trackerNames,
    });
    ReactGA.set(...arguments, trackerNames);
  },
  pageview: function () {
    console.log('ReactGA.pageview', [...arguments, trackerNames], {
      trackers,
      trackerCodes,
      trackerNames,
    });
    ReactGA.pageview(...arguments, trackerNames);
  },
  modalview: function () {
    console.log('ReactGA.modalview', [...arguments, trackerNames], {
      trackers,
      trackerCodes,
      trackerNames,
    });
    ReactGA.modalview(...arguments, trackerNames);
  },
  event: function () {
    console.log('ReactGA.event', [...arguments, trackerNames], {
      trackers,
      trackerCodes,
      trackerNames,
    });
    ReactGA.event(...arguments, trackerNames);
  },
  outboundLink: function () {
    console.log('ReactGA.outboundLink', [...arguments, trackerNames], {
      trackers,
      trackerCodes,
      trackerNames,
    });
    ReactGA.outboundLink(...arguments, trackerNames);
  },
  exception: function () {
    console.log('ReactGA.exception', [...arguments, trackerNames], {
      trackers,
      trackerCodes,
      trackerNames,
    });
    ReactGA.exception(...arguments, trackerNames);
  },

  trackExternalLink: function (e) {
    e.preventDefault();
    var t = e.currentTarget || e.target;
    var href = t.href;
    var text = (t.innetText || '').replace(/^[\s\t\n\r]+|[\s\t\n\r]+$/gi, '');
    var props = Object.assign(
      { label: text || 'external click' },
      { ...t.dataset }
    );
    console.log('ReactGA.trackExternalLink', { e, t, href, props });
    ReactGA.outboundLink(
      props,
      function () {
        window.open(href);
      },
      trackerNames
    );
  },
});

// record the first pageview because listen will not be called
MultipleTrackerGA.pageview(window.location.pathname);

//TODO:
//ReactGA.ga('trackerGlobal.require', 'ecommerce');

export default MultipleTrackerGA;
