import React, { useEffect } from 'react';
import { BaseConfig, ConfigProvider } from './lib/config';

import './App.scss';
import 'react-block-ui/style.css';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

//import { Elements } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';

import {
  Admin,
  Resource,
  //ListGuesser,
  //ShowGuesser,
  resolveBrowserLocale,
} from 'react-admin';

import './lib/security';
import authProvider from './lib/authProvider';
import dataProvider from './lib/dataProvider';
import i18nProvider from './lib/i18nProvider';
import * as async from './lib/async';

//import InvoiceIcon from '@material-ui/icons/Receipt';
import JobIcon from '@material-ui/icons/LocalShipping';
//import CalendarIcon from '@material-ui/icons/EventOutlined';
//import ItemIcon from '@material-ui/icons/ToysOutlined';
//import QuoteIcon from '@material-ui/icons/FormatQuoteOutlined';
import InvoiceIcon from '@material-ui/icons/DescriptionOutlined';
import ProductIcon from '@material-ui/icons/ToysOutlined';
//import AddressIcon from '@material-ui/icons/ContactsOutlined';
//import ProfileIcon from '@material-ui/icons/Person';
import SubscriptionIcon from '@material-ui/icons/Update';
import ReminderIcon from '@material-ui/icons/AddAlarmRounded';
import PaymentIcon from '@material-ui/icons/AccountBalanceWallet';

//import jsonServerProvider from 'ra-data-json-server';
//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

import 'lib/install';

import { MyTheme } from './theme/MyTheme';
import { MyLayout } from './theme/MyLayout';
import { MyLogoutButton } from './theme/MyLogout';

import history from './lib/history';
// doesn't work in multi-tenant cross-domain apps

import customRoutes from './routes';
import customReducers from './reducers';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const Login = async.Page('Login');
const Dashboard = async.Page('Dashboard');
//const Profile = async.Resource('Profile');

//const Quote = async.Resource('Quote');
//const QuoteJob = async.Resource('QuoteJob');
//const QuoteItem = async.Resource('QuoteItem');
const Invoice = async.Resource('Invoice');
const InvoiceJob = async.Resource('InvoiceJob');
const InvoiceItem = async.Resource('InvoiceItem');
const Product = async.Resource('Product');
const Subscription = async.Resource('Subscription');
const Reminder = async.Resource('Reminder');
const Payment = async.Resource('Payment');

//const Contact = async.Resource('Contact');
//const Address = async.Resource('Address');
//const Place = async.Resource('Place');

export const App = () => {
  console.log('App()');
  //  if (typeof window !== 'undefined') {
  //    const { hash = '', pathname = '', search = '' } = window.location;
  //    const basepath = window.REACT_APP_BASE_PATH || '/';
  //    useEffect(() => {
  //      console.log('App() landing', { pathname, hash, search });
  //      if (pathname !== basepath) {
  //        const hashify = '/#' + pathname + search;
  //        console.log('App() landing hashify', { hashify });
  //        window.location = hashify;
  //      }
  //    }, [hash, search, pathname]);
  //    if (pathname !== basepath) {
  //      console.log('App() abort: do not render until we have a has', { hash });
  //      return 'Please wait...';
  //    }
  //  }

  //  const api = dataProvider(Config);
  //  const [completeConfig, loadedConfig] = React.useState({});
  //  useEffect(() => {
  //    api.get('/config').then((config) => {
  //      loadedConfig({ ...Config, ...config.data });
  //    });
  //}, []);

  return (
    <ConfigProvider>
      {/* <Elements stripe={stripePromise}> */}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={MyTheme}>
          <CssBaseline />

          <Admin
            history={history}
            // doesn't work in multi-tenant cross-domain apps

            theme={MyTheme}
            layout={MyLayout}
            logout={MyLogoutButton}
            loginPage={Login}
            dashboard={Dashboard}
            initialState={() => ({ admin: {} })}
            authProvider={authProvider(BaseConfig)}
            dataProvider={dataProvider(BaseConfig)}
            i18nProvider={i18nProvider}
            locale={resolveBrowserLocale()}
            customRoutes={customRoutes}
            customReducers={customReducers}
          >
            <Resource
              name="or.invoice"
              options={{ label: 'Orders' }}
              icon={InvoiceIcon}
              show={Invoice.Show}
              list={Invoice.List}
            />
            <Resource name="or.invoice.component" />
            <Resource name="or.invoice.signature" />
            <Resource name="or.invoice.image" />
            <Resource name="or.invoice.audio" />
            <Resource name="or.invoice.video" />
            <Resource name="or.invoice.coupon" />
            <Resource name="or.invoice.discount" />
            <Resource name="or.invoice.refund" />
            <Resource name="or.invoice.transaction" />
            <Resource name="or.invoice.billination" />
            <Resource name="or.invoice.shipping" />
            <Resource name="or.invoice.destination" />
            <Resource name="or.invoice.occasion" />
            {/*             
              <Resource name="or.invoice.job" />
              */}
            <Resource
              name="or.invoice.job"
              // options={{ label: 'Jobs' }}
              icon={JobIcon}
              show={InvoiceJob.Show}
              // list={InvoiceJob.List}
            />
            <Resource
              name="or.invoice.item"
              //options={{label: "Products"}}
              //icon={ItemIcon}
              show={InvoiceItem.Show}
              list={InvoiceItem.List}
            />
            <Resource name="or.invoice.item.delivery" />
            <Resource name="or.invoice.item.commitment" />
            {/*
            <Resource
              name="or.quote"
              options={{ label: 'Quotes' }}
              icon={QuoteIcon}
              show={Quote.Show}
              list={Quote.List}
            />
            <Resource name="or.quote.component" />
            <Resource name="or.quote.signature" />
            <Resource name="or.quote.image" />
            <Resource name="or.quote.audio" />
            <Resource name="or.quote.video" />
            <Resource name="or.quote.job" />
            <Resource name="or.quote.item" />
            <Resource name="or.quote.coupon" />
            <Resource name="or.quote.discount" />
            <Resource name="or.quote.billination" />
            <Resource name="or.quote.destination" />
            */}
            {/*
                  <Resource name="or.profile" 
                      options={{label: "Account Profile"}} 
                      icon={ProfileIcon} 
                      show={Profile.Show} 
                      list={Profile.List} 
                      sort={{ field: 'name', order: 'ASC' }}
                  />
                  <Resource name="or.profile.address" 
                      options={{label: "Address Book"}} 
                      icon={AddressIcon} 
                      show={Address.Show} 
                      list={Address.List} 
                      sort={{ field: 'name', order: 'ASC' }}
                  />
                  */}
            <Resource name="or.page" />
            <Resource name="or.page.album" />
            <Resource name="or.page.attachment" />
            <Resource name="or.page.audio" />
            <Resource name="or.page.brand" />
            <Resource name="or.page.brochure" />
            <Resource name="or.page.collection" />
            <Resource name="or.page.colour" />
            <Resource name="or.page.comment" />
            <Resource name="or.page.download" />
            <Resource name="or.page.faq" />
            <Resource name="or.page.file" />
            <Resource name="or.page.image" />
            <Resource name="or.page.model" />
            <Resource name="or.page.price" />
            <Resource name="or.page.review" />
            <Resource name="or.page.scrap" />
            <Resource name="or.page.size" />
            <Resource name="or.page.video" />
            <Resource
              name="or.product"
              options={{ label: 'Catalogue' }}
              icon={ProductIcon}
              show={Product.Show}
              list={Product.List}
            />
            <Resource name="or.product.album" />
            <Resource name="or.product.attachment" />
            <Resource name="or.product.audio" />
            <Resource name="or.product.brand" />
            <Resource name="or.product.brochure" />
            <Resource name="or.product.collection" />
            <Resource name="or.product.colour" />
            <Resource name="or.product.comment" />
            <Resource name="or.product.download" />
            <Resource name="or.product.faq" />
            <Resource name="or.product.file" />
            <Resource name="or.product.image" />
            <Resource name="or.product.model" />
            <Resource name="or.product.price" />
            <Resource name="or.product.review" />
            <Resource name="or.product.scrap" />
            <Resource name="or.product.size" />
            <Resource name="or.product.video" />
            <Resource name="or.service" />
            <Resource name="or.service.option" />

            <Resource
              name="or.account.subscription"
              options={{ label: 'Subscriptions' }}
              icon={SubscriptionIcon}
              show={Subscription.Show}
              list={Subscription.List}
            />
            <Resource
              name="or.account.payment_method"
              options={{ label: 'Payment Methods' }}
              icon={PaymentIcon}
              show={Payment.Show}
              list={Payment.List}
            />
            <Resource
              name="or.account.reminder"
              options={{ label: 'Reminders' }}
              icon={ReminderIcon}
              show={Reminder.Show}
              list={Reminder.List}
              edit={Reminder.Edit}
              create={Reminder.Create}
            />
          </Admin>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      {/* </Elements> */}
    </ConfigProvider>
  );
};

export default App;
