import React, { useState, useEffect, useContext } from 'react';
import localforage from 'localforage';

import { ParseContext, EnvironmentConstants } from './context';
const context = ParseContext(process.env);
const constants = EnvironmentConstants(process.env);
window.localforage = localforage;

console.log('Config start', {
  env: process.env,
  constants,
  context,
  parsed: ParseContext(process.env),
});

const env = process.env.NODE_ENV;
const dev = env.match(/^(dev|192|127|diego)/gi) ? true : false;

export const REGIONS = {
  uk: {
    region: 'uk',
    country: 'gb',
    currency: 'gbp',
    analytics: 'UA-4414122-9',
    www_domain: dev ? 'uk-dev.only-roses.com' : 'www.only-roses.co.uk',
    api_domain: dev ? 'uk-dev.only-roses.com' : 'uk-api.only-roses.com',
    app_domain: 'uk.only-roses.app', //'uk-app.only-roses.com',
    apikey: '52dc7f4200e674e6314a8930d75f04cb71bbc7281beee427309f51b22dd22a8b',
  },
  eu: {
    region: 'eu',
    country: 'de',
    currency: 'eur',
    analytics: 'UA-4414122-9',
    www_domain: dev ? 'eu-dev.only-roses.com' : 'www.only-roses.eu',
    api_domain: dev ? 'eu-dev.only-roses.com' : 'eu-api.only-roses.com',
    app_domain: 'eu.only-roses.app', //'eu-app.only-roses.com',
    apikey: '52dc7f4200e674e6314a8930d75f04cb71bbc7281beee427309f51b22dd22a8b',
  },
  qa: {
    region: 'qa',
    country: 'qa',
    currency: 'qar',
    analytics: 'UA-36634575-3',
    www_domain: dev ? 'qa-dev.only-roses.com' : 'www.only-roses.qa',
    api_domain: dev ? 'qa-dev.only-roses.com' : 'qa-api.only-roses.com',
    app_domain: 'qa.only-roses.app', //'qa-app.only-roses.com',
    apikey: '52dc7f4200e674e6314a8930d75f04cb71bbc7281beee427309f51b22dd22a8b',
  },
  ae: {
    region: 'ae',
    country: 'ae',
    currency: 'aed',
    analytics: 'UA-43716309-4',
    www_domain: dev ? 'ae-dev.only-roses.com' : 'www.only-roses.ae',
    api_domain: dev ? 'ae-dev.only-roses.com' : 'ae-api.only-roses.com',
    app_domain: 'ae.only-roses.app', //'ae-app.only-roses.com',
    apikey: '52dc7f4200e674e6314a8930d75f04cb71bbc7281beee427309f51b22dd22a8b',
  },
  us: {
    region: 'us',
    country: 'us',
    currency: 'usd',
    analytics: 'UA-91369093-3',
    www_domain: dev ? 'us-dev.only-roses.com' : 'www.only-roses.com',
    api_domain: dev ? 'us-dev.only-roses.com' : 'us-api.only-roses.com',
    app_domain: 'us.only-roses.app', //'us-app.only-roses.com',
    apikey: '52dc7f4200e674e6314a8930d75f04cb71bbc7281beee427309f51b22dd22a8b',
  },
  kw: {
    region: 'kw',
    country: 'kw',
    currency: 'kwd',
    analytics: 'UA-75784877-3',
    www_domain: dev ? 'kw-dev.only-roses.com' : 'www.only-roses.com.kw',
    api_domain: dev ? 'kw-dev.only-roses.com' : 'kw-api.only-roses.com',
    app_domain: 'kw.only-roses.app', //'kw-app.only-roses.com',
    apikey: '52dc7f4200e674e6314a8930d75f04cb71bbc7281beee427309f51b22dd22a8b',
  },
  sa: {
    region: 'sa',
    country: 'sa',
    currency: 'sar',
    analytics: 'UA-75237046-3',
    www_domain: dev ? 'sa-dev.only-roses.com' : 'www.only-roses.sa.com',
    api_domain: dev ? 'sa-dev.only-roses.com' : 'sa-api.only-roses.com',
    app_domain: 'sa.only-roses.app', //'sa-app.only-roses.com',
    apikey: '52dc7f4200e674e6314a8930d75f04cb71bbc7281beee427309f51b22dd22a8b',
  },
  lb: {
    region: 'lb',
    country: 'lb',
    currency: 'lbp',
    analytics: 'UA-107677556-2',
    www_domain: dev ? 'lb-dev.only-roses.com' : 'lb.only-roses.com',
    api_domain: dev ? 'lb-dev.only-roses.com' : 'lb-api.only-roses.com',
    app_domain: 'lb.only-roses.app', //'lb-app.only-roses.com',
    apikey: '52dc7f4200e674e6314a8930d75f04cb71bbc7281beee427309f51b22dd22a8b',
  },
};

export const domain = context.domain || window.location.hostname;
export const region = (() => {
  let romain = domain.replace(/^dev\./, 'www.').replace(/-dev\./, '.');
  let region = (romain.match(/^\w\w/) || [''])[0];
  if (!region) {
    if (romain.endsWith('only-roses.qa')) region = 'qa';
    if (romain.endsWith('only-roses.ae')) region = 'ae';
    if (romain.endsWith('only-roses.co.uk')) region = 'uk';
    if (romain.endsWith('only-roses.com')) region = 'us';
    if (romain.endsWith('only-roses.sa.com')) region = 'sa';
    if (romain.endsWith('only-roses.com.kw')) region = 'kw';
    if (romain.endsWith('only-roses.eu')) region = 'eu';
    if (romain.endsWith('only-roses.lb')) region = 'lb';
  }
  return region || 'qa';
})();

export const regionContext = REGIONS[region] || REGIONS['uk'];
export const regionConfig = {
  ...regionContext,
  API_BASE: `https://${regionContext.api_domain}/api`,
  website: `https://${regionContext.www_domain}`,
};

export const BaseConfig = {
  ...context,
  ...regionConfig,
  ...{
    env,
    dev,
    domain,
    region,
    app_link: dev
      ? `https://localhost:8888/`
      : `https://${regionConfig.app_domain}/`,
  },
  // "client": "dashboard",
  // "apikey": "jwt?",
  // "domain": "domain.com"
};

console.log('BaseConfig done', {
  env,
  dev,
  process_env: process.env,
  constants,
  context,
  parsed: ParseContext(process.env),
  REGIONS,
  region,
  domain,
  regionConfig,
  BaseConfig,
});

//export const ConfigContext = React.createContext({});
//
//export const useConfig = () => {
//  const store = useContext(ConfigContext);
//  return store;
//};

// await fetch('https://uk-dev.only-roses.com/api/config',{headers:{app:'whatever'}}).then(x=>x.json())

const CONFIG_RESOURCE = 'config'; // /x/api/dash/config
const CONFIG_REFRESH_INTERVAL = 5 * 60 * 1000;

const getConfig = async () => {
  console.info('getConfig', { CONFIG_RESOURCE });
  const ConfigFromServer = await fetch(
    `https://${BaseConfig.api_domain}/api/${CONFIG_RESOURCE}`,
    {
      headers: { app: 'fyneworks' },
    }
  )
    .then((x) => {
      console.log('getConfig result return x.json();', { x });
      return x.json();
    })
    .then((x) => {
      console.log('getConfig result return x.data;', { x });
      return x.data;
    });
  console.log('getConfig result', ConfigFromServer);
  return ConfigFromServer;
};

export const ConfigContext = React.createContext({});

export const ConfigProvider = ({ children }) => {
  console.info('ConfigProvider', { BaseConfig, CONFIG_RESOURCE });
  const [DATA, setDATA] = useState({
    loading: false,
    loaded: false,
    error: null,
    data: [],
  });

  const loadConfig = () => {
    console.info('loadConfig', { CONFIG_RESOURCE });
    setDATA({ ...DATA, loading: true, error: null });
    return getConfig()
      .then((ConfigFromServer) => {
        console.info('loadConfig', { ConfigFromServer });
        if (ConfigFromServer) {
          console.log('saveConfig in localforage', ConfigFromServer);
          localforage.setItem(CONFIG_RESOURCE, ConfigFromServer).then(() => {
            console.log('return ConfigFromServer', ConfigFromServer);
            setDATA({
              ...DATA,
              ...BaseConfig,
              ...ConfigFromServer,
              loaded: true,
            });
          });
        } else {
          setDATA({
            ...DATA,
            loading: false,
            loaded: false,
            error: 'not ready',
          });
        }
      })
      .catch((error) => {
        setDATA({ ...DATA, loading: false, loaded: false, error });
      });
  };

  useEffect(async () => {
    let timeout_id = setInterval(loadConfig, CONFIG_REFRESH_INTERVAL);

    localforage.getItem(CONFIG_RESOURCE).then((ConfigFromStorage) => {
      console.info('cataloge localforage', {
        CONFIG_RESOURCE,
        ConfigFromStorage,
      });
      if (ConfigFromStorage) {
        setDATA({ ...DATA, ...BaseConfig, ...ConfigFromStorage, loaded: true });
        console.info('got ConfigFromStorage', ConfigFromStorage);
      } else {
        console.info('loadConfig();');
        loadConfig();
      }
    });

    return () => {
      clearInterval(timeout_id);
    };
  }, []);

  return (
    <ConfigContext.Provider value={DATA}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const store = useContext(ConfigContext);
  return store;
};

//export default useConfig;
export default BaseConfig;
