import { createTheme } from '@material-ui/core/styles';
//import grey from '@material-ui/core/colors/grey';
//import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export const primaryColour = process.env.REACT_APP_primaryColour;
export const secondaryColour = process.env.REACT_APP_secondaryColour;
export const lightTextColour = process.env.REACT_APP_lightTextColour;

console.log('THEME PROPS', { primaryColour, secondaryColour, lightTextColour });

// create blank theme to expose breakpoints,
const theme = createTheme({
  palette: {
    primary: {
      //main: green[900],
      main: primaryColour,
    },
    secondary: {
      //main: green[900],
      main: secondaryColour,
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      `FuturaPTLight`,
      `"Century Gothic"`,
      `CenturyGothic`,
      `"Apple Gothic"`,
      `AppleGothic`,
      `"URW Gothic L"`,
      `"Avant Garde"`,
      `sans-serif`,
    ].join(','),
  },

  components: {
    RaUserMenu: {
      styleOverrides: {
        width: 300,
        minWidth: 300,
        background: 'red',
      },
    },
  },
});

// then overwrite theme and we can use breakpoints
// https://stackoverflow.com/a/56320113
export const MyTheme = {
  ...theme,

  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        background: primaryColour, // + '!important', //theme.palette.primary, //primaryColour,
        color: lightTextColour, // + '!important',
        // Name of the rule
        //color: 'white', // Some CSS
        '&:hover': {
          background: secondaryColour + '!important', //theme.palette.secondary, //secondaryColour & ' !important',
          //color: 'red !important',
        },
      },
      label: {
        color: lightTextColour, // + '!important',
        // Name of the rule
        //color: 'white', // Some CSS
        '&:hover': {
          background: secondaryColour + '!important', //theme.palette.secondary, //secondaryColour & ' !important',
          //color: 'red !important',
        },
      },
    },
    MuiTableRow: {
      root: {
        // background: '#090',
        '&:last-child td': {
          borderBottom: 'none', // remove the border for the last child cell of the last row
          // background: 'red',
        },
      },
    },
    MuiBottomNavigation: {
      root: {
        position: 'fixed',
        bottom: 0,
        width: '100vw',
        zIndex: 2,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: '#fff',
      },
    },
    MuiToolbar: {
      root: {
        xxx_maxWidth: '800px',
        width: '100%',
        margin: 'auto',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%', //background: 'red',
      },
    },
    MuiCard: {
      root: {
        width: '100%',
        marginBottom: '20px',
        //boxShadow: 'none',
      },
    },
    MuiPaper: {
      root: {
        //border: 0,
        //padding: 0,
        //margin: 0,
      },
    },
    MuiCardContent: {
      root: {
        width: '100%', //background: 'red',
        //padding: 20,
      },
    },
    MuiCardActionArea: {
      root: {
        width: '100%', //background: 'red',
      },
    },
    MuiListItemIcon: {
      root: {
        //marginLeft: '10px',
      },
    },
    //RaLabeled: {
    //  root: {
    //    width: '100%', //background: 'red',
    //  },
    //},
    RaReferenceField: {
      link: {
        color: '#fff',
      },
    },
    RaShow: {
      card: {
        boxShadow: 'none',
      },
    },
    RaCardContentInner: {
      root: {
        border: 0,
      },
    },
    RaAppBar: {
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        flexJustifyItems: 'center',
      },
      menuButton: {
        display: 'none',
      },
    },
    RaSidebar: {
      drawerPaper: {
        //right: 0,
        //left: 'auto',
        //zIndex: 9,
      },
    },
    RaSingleFieldList: {
      root: {
        width: '100%', //background: 'red',
      },
      link: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
    RaToolbar: {
      mobileToolbar: {
        //bottom: 60,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
      },
    },
  },
};

export default MyTheme;
